if (!process.env.REACT_APP_API_KEY){
    throw new Error('REACT_APP_API_KEY is not found.');
}

if (!process.env.REACT_APP_SPREADSHEET_ID){
    throw new Error('REACT_APP_SPREADSHEET_ID is not found.');
}


const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
    spreadsheetId: process.env.REACT_APP_SPREADSHEET_ID,
    range: "B7:H44",
};

export default config;
