import config from "../config/config";

export async function load(callback) {
    // window.gapi.client.load("sheets", "v4", async () => {
    const response = await window.gapi.client.sheets.spreadsheets.values
        .get({
            spreadsheetId: config.spreadsheetId,
            range: config.range
        });
    const rows = response.result.values;
    const trimmedRows = rows.map(row => {
        return row.map(value => {
            return value.trim();
        })
    });

    callback(trimmedRows);
    // });
}
