// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import config from "../config/config";
import {load} from "../helpers/spreadsheet";
import {setIntervalImmediately} from "../helpers/interval";


export function Schedule() {
    const [schedules, setSchedules] = useState([]);
    const [currentDay, setCurrentDay] = useState(-1);

    useEffect(() => {
        setIntervalImmediately(() => getCurrentDayOfTheWeek(), 1000);

        const runGoogleDocs = async () => {
            if (!window.gapi.client) {
                await window.gapi.load("client", initClient);

                return;
            }

            await load(onLoad);
        }

        setIntervalImmediately(() => runGoogleDocs(), 10000);
    }, []);


    const initClient = () => {
        window.gapi.client
            .init({
                apiKey: config.apiKey,
                discoveryDocs: config.discoveryDocs
            })
            .then(() => {
                load(onLoad);
            });
    };

    const getCurrentTime = () => {
        const d = new Date();
        const m = d.getMinutes();
        const h = d.getHours();
        return h * 60 + m;
    }

    const onLoad = (data, error) => {
        setSchedules(data);
    };

    const getCurrentDayOfTheWeek = () => {
        const date = new Date();
        setCurrentDay(date.getDay());
    }

    const getSplitTimes = (timesAsString) => {
        const splitTimes = timesAsString.split('-');
        return splitTimes.map(time => time.trim());
    }

    const getMobsterStatus = (timesAsString) => {
        if (!timesAsString) {
            return;
        }

        const multipleTimes = timesAsString.split('&');
        const multipleTimesTrimmed = multipleTimes.map(time => time.trim());
        // const multipleTimesTrimmed2 = multipleTimesTrimmed.map(time => time.replace('*', ''));

        let present = false;
        let online = false;

        for (const timesAsString of multipleTimesTrimmed) {

            const isOnlineTime = timesAsString.includes('*');

            const timesAsStringWithoutOcc = timesAsString.replace('*', '');

            const timesInMinutes = activeMinutes(timesAsStringWithoutOcc);

            if (!timesInMinutes) {
                continue;
            }

            if (getCurrentTime() >= timesInMinutes[0] && getCurrentTime() <= timesInMinutes[1]) {
                present = true;
                online = isOnlineTime;
                break;
            }
        }

        return [present, online];
    }

    const activeMinutes = (timesAsString) => {
        const splitTimes = getSplitTimes(timesAsString);

        if (splitTimes.length !== 2) {
            return;
        }

        const startTimeInMinutes = transformToMinutes(splitTimes[0]);
        const endTimeInMinutes = transformToMinutes(splitTimes[1]);

        return [startTimeInMinutes, endTimeInMinutes];
    }

    const transformToMinutes = (timeAsString) => {
        const hoursAndMinutes = timeAsString.split(':'); // split it at the colons
        return hoursAndMinutes[0] * 60 + +hoursAndMinutes[1];
    }

    const getCurrentDayTimes = (schedule) => {
        let scheduleIndex = currentDay + 1;

        scheduleIndex = scheduleIndex < 2 ? 2 : scheduleIndex;
        scheduleIndex = scheduleIndex > 6 ? 6 : scheduleIndex;

        return schedule[scheduleIndex];
    }

    const formatTimes = (timesAsString) => {
        if (!timesAsString) {
            return;
        }
        const multipleTimes = timesAsString.split('&');
        const multipleTimesTrimmed = multipleTimes.map(time => time.trim());

        let timesAsHTML = '';


        for (let i = 0; i < multipleTimesTrimmed.length; i++) {
            if (multipleTimesTrimmed[i].includes('*')) {
                multipleTimesTrimmed[i] = multipleTimesTrimmed[i].replace('*', '');
                let splitTimes = getSplitTimes(multipleTimesTrimmed[i]);
                if (splitTimes.length === 2) {
                    multipleTimesTrimmed[i] = `<span class="online">${formatTime(splitTimes[0])} - ${formatTime(splitTimes[1])}</span>`
                } else {
                    multipleTimesTrimmed[i] = `<span class="online">${formatTime(multipleTimesTrimmed[i])}</span>`
                }
            } else {
                let splitTimes = getSplitTimes(multipleTimesTrimmed[i]);
                if (splitTimes.length === 2) {
                    multipleTimesTrimmed[i] = `<span>${formatTime(splitTimes[0])} - ${formatTime(splitTimes[1])}</span>`
                } else {
                    multipleTimesTrimmed[i] = `<span>${formatTime(multipleTimesTrimmed[i])}</span>`
                }
            }

            if (i > 0) {
                timesAsHTML += ' & '
            }

            timesAsHTML += multipleTimesTrimmed[i];
        }

        return timesAsHTML;
    }

    const formatTime = (timeString) => {
        // if no : is present return the string
        if (!timeString.includes(':')) {
            return timeString;

        }
        const time = timeString.split(':');
        // add leading zero if needed
        if (time[0].length === 1) {
            time[0] = '0' + time[0];
        }
        if (time[1].length === 1) {
            time[1] = '0' + time[1];
        }

        return `${time[0]}:${time[1]}`;

    }

    function getTeams(scheduleElement) {

        if (!scheduleElement.includes('/')) {
            return "<span class='" + scheduleElement.replaceAll(" ", "_") + "'>" + scheduleElement + "</span>";
        }

        const teams = scheduleElement.split('/');
        let teamsHtml = '';
        for (let i = 0; i < teams.length; i++) {
            let team = teams[i].trim();
            if (i > 0) {
                teamsHtml += ' / '
            }
            teamsHtml += "<span class='" + team.replaceAll(" ", "_") + "'>" + team + "</span>";
        }

        return teamsHtml;

    }

    const schedulesHtml = schedules.map((schedule, index) => {
        const mobsterStatus = getMobsterStatus(getCurrentDayTimes(schedule));

        let present = '';
        let online = '';

        if (mobsterStatus) {
            present = mobsterStatus[0] ? 'present' : '';
            online = mobsterStatus[1] ? 'onlineTime' : '';
        }

        return (
            <tr key={index} className={``}>
                <td className={`${present} ${online}`} dangerouslySetInnerHTML={{__html: schedule[0]}} />
                <td
                    dangerouslySetInnerHTML={{__html: getTeams(schedule[1])}}/>
                <td className={`${currentDay === 1 ? 'currentDay' : ''} ${present} ${online} ${currentDay === 1}`}
                    dangerouslySetInnerHTML={{__html: formatTimes(schedule[2])}}/>
                <td className={`${currentDay === 2 ? 'currentDay' : ''} ${present} ${online} ${currentDay === 2 }`}
                    dangerouslySetInnerHTML={{__html: formatTimes(schedule[3])}}/>
                <td className={`${currentDay === 3 ? 'currentDay' : ''} ${present} ${online} ${currentDay === 3 }`}
                    dangerouslySetInnerHTML={{__html: formatTimes(schedule[4])}}/>
                <td className={`${currentDay === 4 ? 'currentDay' : ''} ${present} ${online} ${currentDay === 4 }`}
                    dangerouslySetInnerHTML={{__html: formatTimes(schedule[5])}}/>
                <td className={`${currentDay === 5 ? 'currentDay' : ''} ${present} ${online} ${currentDay === 5 }`}
                    dangerouslySetInnerHTML={{__html: formatTimes(schedule[6])}}/>
            </tr>
        )
    })

    if (schedulesHtml.length === 0) {

        return (
            <div className="LoadingSpinner">
                <svg width="512" height="512" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff"
                          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                          className="spinner_P7sC"/>
                </svg>
            </div>
        );

    }
    return (
        <div>
            <table className="schedule-display">
                <tbody>
                <tr>
                    <td></td>
                    <td>Team</td>
                    <td className={currentDay === 1 ? 'currentDay header' : ''}>Monday</td>
                    <td className={currentDay === 2 ? 'currentDay header' : ''}>Tuesday</td>
                    <td className={currentDay === 3 ? 'currentDay header' : ''}>Wednesday</td>
                    <td className={currentDay === 4 ? 'currentDay header' : ''}>Thursday</td>
                    <td className={currentDay === 5 ? 'currentDay header' : ''}>Friday</td>
                </tr>
                {schedulesHtml}
                </tbody>
            </table>
        </div>
    );
};
