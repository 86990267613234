import React, {useEffect, useState} from 'react';
import './App.css';
import {Schedule} from "./components/Schedule";

import {ReactComponent as B302Logo} from "./assets/images/b302-logo.svg";
import {ReactComponent as NewDesignersLogo} from "./assets/images/new-designers-logo.svg";
import {setIntervalImmediately} from "./helpers/interval";

function App() {

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    const type = process.env.REACT_APP_TYPE;
    if (!type){
        throw new Error('REACT_APP_TYPE is not found.');
    }

    const setDocumentTitle = () => {
        let title = '';

        if(type === 'b302'){
            title = 'B302';
        } else if(type === 'new-designers'){
            title = 'New Designers';
        }

        title += ' - Schedule';
        document.title = title;
    };

    useEffect(() => {
        setIntervalImmediately(() => getDate(), 1000);
        setIntervalImmediately(() => getTime(), 1000);
        setDocumentTitle();
    }, []);

    const getDate = () => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date();
        const dayName = days[date.getDay()];
        setDate(dayName + ' ' + date.getDate() + ' - ' + (date.getMonth() + 1))
    }

    const getTime = () => {
        const date = new Date();
        const m = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        const h = (date.getHours() < 10 ? '0' : '') + date.getHours();
        setTime(h + ':' + m);
    }

    return (
        <div className="App">
            <header>
                <div className="logo">
                    {type === 'b302' && <B302Logo/>}
                    {type === 'new-designers' && <NewDesignersLogo/>}
                </div>
                <div className="date">{date}</div>
                <div className="time">{time}</div>
            </header>
            <main>
                <Schedule/>
            </main>
        </div>
    );
}

export default App;
